
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as kartQO6DEdUJqatIfihZGLRg8spvPdyTNw297IjX9a9g2DYMeta } from "/home/bede/bede/pages/kart.vue?macro=true";
import { default as indexHbujX_45JL0qCd3cg5zVZb9x32jb9o1uCudM_45bwfqGpf0Meta } from "/home/bede/bede/pages/index.vue?macro=true";
import { default as om_45osseaMWWn0JFiRHG3n6SK4Ax2U_45Bzo9HkkC96p7q5N_45js8Meta } from "/home/bede/bede/pages/om-oss.vue?macro=true";
import { default as medlemskapORogxJRdhI2P_45nqiiUa4EqZtQrYiJlpuJIFbwWF7IIcMeta } from "/home/bede/bede/pages/medlemskap.vue?macro=true";
import { default as aktiviteterr9jLPRzfJG_456qo5J3zmVAlQI7GLftkF1pZdZwOPbzKUMeta } from "/home/bede/bede/pages/aktiviteter.vue?macro=true";
import { default as givertjenesteG9JMCw_45KjFvD98RFoT3d_45ceSritDuFxVxfelSNQ_BfAMeta } from "/home/bede/bede/pages/givertjeneste.vue?macro=true";
import { default as indexLVuclhum6hRboNlrl_lSR3jM0GxqpMKvuQrSly1HbZYMeta } from "/home/bede/bede/pages/program/index.vue?macro=true";
import { default as indexTveexvnh3TMPQjpjGkaBiLNeR3CiRklWVoYuh0SvF_0Meta } from "/home/bede/bede/pages/vuefire/index.vue?macro=true";
import { default as loginIRjgWM7zfei1Y3TCWTZFgMcuEXs_wP6ReCe7UrTrMV4Meta } from "/home/bede/bede/pages/vuefire/login.vue?macro=true";
import { default as users8ixyrmI41tlBi9wjyexF00yn3mGPAwE6XlRKeRfL4psMeta } from "/home/bede/bede/pages/vuefire/users.vue?macro=true";
import { default as _91event_93BbhyYgJCjyf_daRdElGEFUqIt6HkAcAZzc6jvvOBgh4Meta } from "/home/bede/bede/pages/program/[event].vue?macro=true";
import { default as countere__45g5RtwUnox1YoSpadz6fqUPBjnEIgdv6u4atC51QwMeta } from "/home/bede/bede/pages/vuefire/counter.vue?macro=true";
import { default as storageWB4dZVkgBBVIXIHjC_d7n7Hosc0EV_45Bsd0kJIu2XSVgMeta } from "/home/bede/bede/pages/vuefire/storage.vue?macro=true";
import { default as _91event_93CZk1MwCsQltRSR5PaMvJunVg2y7Ldo_45APQK0U_45WrTA0Meta } from "/home/bede/bede/pages/calendar/[event].vue?macro=true";
import { default as legg_45til_45kalenderlhY6mKFzWZqpkswQ_Q2uKOEm5Q0_4BgxOc3WeXJF6n8Meta } from "/home/bede/bede/pages/legg-til-kalender.vue?macro=true";
import { default as analyticstSJq2pPooqq98pn5Yg7EfCrj51IjSoQilNQ2nb3jN8cMeta } from "/home/bede/bede/pages/vuefire/analytics.vue?macro=true";
import { default as app_45checkyJQqMj9yEonrhVxljLk_iZ73Gbxo2BlxWlSP4pADEEsMeta } from "/home/bede/bede/pages/vuefire/app-check.vue?macro=true";
import { default as new3v8Ru3zJt4j2ZH6_45pXf_U_lYylZIpFh5Woh3kwXhdLQMeta } from "/home/bede/bede/pages/vuefire/posts/new.vue?macro=true";
import { default as personvernc_f29SKhOe5__45WnAovT90_oNffkihGbPTBpw73otLLYMeta } from "/home/bede/bede/pages/juridisk/personvern.vue?macro=true";
import { default as emoji_45panelNu7rk4YU62y_aaeSPxiwQFoPl74GsnfD4At7yuUJvEsMeta } from "/home/bede/bede/pages/vuefire/emoji-panel.vue?macro=true";
import { default as bruksvilkarvgDnjXkr1dmVcIReb3_45E3fvQRCmbu_45HWkkN3_FGosP0Meta } from "/home/bede/bede/pages/juridisk/bruksvilkar.vue?macro=true";
import { default as database_45todo_45listf0V1wNbK0kg7aYNmWv7GkAecJ1Rbtcfoj0H5p7atSJ0Meta } from "/home/bede/bede/pages/vuefire/database-todo-list.vue?macro=true";
import { default as informasjonskapslerefZaXhCYQ_Nbjfg6SE_45tYsb1_45YNIuzTj5_45Su6mQ9IgUMeta } from "/home/bede/bede/pages/juridisk/informasjonskapsler.vue?macro=true";
export default [
  {
    name: "kart",
    path: "/kart",
    component: () => import("/home/bede/bede/pages/kart.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/bede/bede/pages/index.vue")
  },
  {
    name: "om-oss",
    path: "/om-oss",
    component: () => import("/home/bede/bede/pages/om-oss.vue")
  },
  {
    name: "medlemskap",
    path: "/medlemskap",
    component: () => import("/home/bede/bede/pages/medlemskap.vue")
  },
  {
    name: "aktiviteter",
    path: "/aktiviteter",
    component: () => import("/home/bede/bede/pages/aktiviteter.vue")
  },
  {
    name: "givertjeneste",
    path: "/givertjeneste",
    component: () => import("/home/bede/bede/pages/givertjeneste.vue")
  },
  {
    name: "program",
    path: "/program",
    component: () => import("/home/bede/bede/pages/program/index.vue")
  },
  {
    name: "vuefire",
    path: "/vuefire",
    component: () => import("/home/bede/bede/pages/vuefire/index.vue")
  },
  {
    name: "vuefire-login",
    path: "/vuefire/login",
    meta: loginIRjgWM7zfei1Y3TCWTZFgMcuEXs_wP6ReCe7UrTrMV4Meta || {},
    component: () => import("/home/bede/bede/pages/vuefire/login.vue")
  },
  {
    name: "vuefire-users",
    path: "/vuefire/users",
    meta: users8ixyrmI41tlBi9wjyexF00yn3mGPAwE6XlRKeRfL4psMeta || {},
    component: () => import("/home/bede/bede/pages/vuefire/users.vue")
  },
  {
    name: "program-event",
    path: "/program/:event()",
    component: () => import("/home/bede/bede/pages/program/[event].vue")
  },
  {
    name: "vuefire-counter",
    path: "/vuefire/counter",
    meta: countere__45g5RtwUnox1YoSpadz6fqUPBjnEIgdv6u4atC51QwMeta || {},
    component: () => import("/home/bede/bede/pages/vuefire/counter.vue")
  },
  {
    name: "vuefire-storage",
    path: "/vuefire/storage",
    meta: storageWB4dZVkgBBVIXIHjC_d7n7Hosc0EV_45Bsd0kJIu2XSVgMeta || {},
    component: () => import("/home/bede/bede/pages/vuefire/storage.vue")
  },
  {
    name: "calendar-event",
    path: "/calendar/:event()",
    component: () => import("/home/bede/bede/pages/calendar/[event].vue")
  },
  {
    name: "legg-til-kalender",
    path: "/legg-til-kalender",
    component: () => import("/home/bede/bede/pages/legg-til-kalender.vue")
  },
  {
    name: "vuefire-analytics",
    path: "/vuefire/analytics",
    meta: analyticstSJq2pPooqq98pn5Yg7EfCrj51IjSoQilNQ2nb3jN8cMeta || {},
    component: () => import("/home/bede/bede/pages/vuefire/analytics.vue")
  },
  {
    name: "vuefire-app-check",
    path: "/vuefire/app-check",
    meta: app_45checkyJQqMj9yEonrhVxljLk_iZ73Gbxo2BlxWlSP4pADEEsMeta || {},
    component: () => import("/home/bede/bede/pages/vuefire/app-check.vue")
  },
  {
    name: "vuefire-posts-new",
    path: "/vuefire/posts/new",
    meta: new3v8Ru3zJt4j2ZH6_45pXf_U_lYylZIpFh5Woh3kwXhdLQMeta || {},
    component: () => import("/home/bede/bede/pages/vuefire/posts/new.vue")
  },
  {
    name: "juridisk-personvern",
    path: "/juridisk/personvern",
    component: () => import("/home/bede/bede/pages/juridisk/personvern.vue")
  },
  {
    name: "vuefire-emoji-panel",
    path: "/vuefire/emoji-panel",
    meta: emoji_45panelNu7rk4YU62y_aaeSPxiwQFoPl74GsnfD4At7yuUJvEsMeta || {},
    component: () => import("/home/bede/bede/pages/vuefire/emoji-panel.vue")
  },
  {
    name: "juridisk-bruksvilkar",
    path: "/juridisk/bruksvilkar",
    component: () => import("/home/bede/bede/pages/juridisk/bruksvilkar.vue")
  },
  {
    name: "vuefire-database-todo-list",
    path: "/vuefire/database-todo-list",
    meta: database_45todo_45listf0V1wNbK0kg7aYNmWv7GkAecJ1Rbtcfoj0H5p7atSJ0Meta || {},
    component: () => import("/home/bede/bede/pages/vuefire/database-todo-list.vue")
  },
  {
    name: "juridisk-informasjonskapsler",
    path: "/juridisk/informasjonskapsler",
    component: () => import("/home/bede/bede/pages/juridisk/informasjonskapsler.vue")
  }
]