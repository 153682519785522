import type Location from "@/types/Location";

export default {
  locationName: "Kongsvinger Bedehus",
  img: "/img/bedehuset-fb-bd-n.jpg",
  imgAlt: "Kongsvinger Bedehus",
  imgCaptionHref:
    "https://www.facebook.com/bdungdomsklubb/photos/280983505356200",
  imgCaptionName: "BD Ungdomsklubb",
  membership: [
    [
      { h2: "Medlemskap i Kongsvinger Misjonsforsamling" },
      {
        text: "For å melde seg inn i Kongsvinger Misjonsforsamling, må man være fylt 15 år. Medlemskontingenten for ett år er 100 kroner.",
      },
      {
        linebreak: true,
      },
      {
        text: "Du blir medlem ved å Vippse 100 kroner til 23803 (Kongsvinger Misjonsforsamling) og skrive i meldingen at det gjelder medlemskap.",
      },
      {
        linebreak: true,
      },
      {
        text: "Medlemmene i de lokale foreningene til ",
      },
      {
        link: {
          label: "Normisjon",
          href: "https://www.normisjon.no/vart-arbeid",
          external: true,
        },
      },
      { text: ", " },
      {
        link: {
          label: "Misjonsselskapet (NMS)",
          href: "https://nms.no/om-oss",
          external: true,
        },
      },
      { text: " og " },
      {
        link: {
          label: "Misjonssambandet (NLM)",
          href: "https://nlm.no/om-oss",
          external: true,
        },
      },
      {
        text: ", er automatisk også medlemmer i Kongsvinger Misjonsforsamling.",
      },
    ],
  ],
  donations: [
    [
      { h2: "Givertjeneste" },
      {
        text: "Dersom du ønsker å gi en gave til arbeidet ved Kongsvinger Bedehus, er du velkommen til å bidra til en av disse kontoene:",
      },
      {
        ul: [
          "Kongsvinger Misjonsforsamling: 1822.50.36450",
          "Kongsvinger Misjonsforsamling - Diakoni: 1822.77.07476",
          "Kongsvinger Bedehus: 1822.23.34922",
          "Kongsvinger Nærmiljøsenter: 1822.14.58393",
        ],
      },
      {
        text: "Du kan også Vippse til:",
      },
      {
        ul: [
          "Kongsvinger Misjonsforsamling: 23803",
          "Kongsvinger Nærmiljøsenter: 592340",
        ],
      },
    ],
  ],
  activities: [
    [
      { h2: "Kongsvinger Misjonsforsamling" },
      { h3: "Praise" },
      {
        text: "Den første søndagen hver måned samles vi til Praise. Da synger vi lovsanger sammen (noen moderne og noen tradisjonelle), lytter til en tale, deler nattverd med hverandre og spiser sammen.",
      },
      { h3: "Søndagsmøte" },
      {
        text: "Den nest siste søndagen hver måned holder vi misjonsmøter, vanligvis med talere fra en av misjonsorganisasjonene, informasjon fra misjonsmarken, sanger fra sangboken, noen ganger loddsalg og eksterne bidragsytere, og alltid kaffe og noe godt å bite i.",
      },
      { h3: "Undervisning og samtale" },
      {
        text: "En onsdag i måneden samles vi til undervisning og samtale. Da begynner vi med kaffe og noe å bite i klokka 18.30, og får en innledning til samtale fra klokka 19.00 og utover.",
      },
      { h3: "The Chosen" },
      {
        text: "The Chosen er en TV-serie om Jesus sine utvalgte disipler. En onsdag i måneden setter vi oss foran skjermen og ser 1-2 episode(r), spiser popcorn og prater sammen om det vi har sett. Alle som som ønsker seg en avslappende film-kveld er velkommen!",
      },
    ],
    [
      { h2: "Trosopplæringen i Vinger menighet" },
      { h3: "KingKids" },
      {
        link: {
          label: "KingKids",
          href: "https://www.facebook.com/vingerkingkids",
          external: true,
        },
      },
      {
        text: " - gospelkor for barn og ungdom i Glåmdalsdistriktet. Øvelse med lek og moro hver tirsdag fra 17.00-18.00 i skoleukene.",
      },
    ],
    [
      { h3: "KingTeens" },
      {
        text: "Ungdomskoret KingTeens er for ungdommer mellom 13-19 år. Vi øver på Kongsvinger bedehus hver tirsdag fra 18:15-19:15. Alle er velkommen!",
      },
    ],
    [
      { h2: "Ungdomsklubben BD" },
      {
        link: {
          label: "BD",
          href: "https://www.facebook.com/bdungdomsklubb",
          external: true,
        },
      },
      {
        text: " - ungdomsklubben på Bedehuset - er åpen hver torsdag kl 18:00-22:00 (stengt ved skolefri). Klubben drives av ",
      },
      {
        link: {
          label: "Acta - Barn og unge i Normisjon",
          href: "https://acta.no/om-acta",
          external: true,
        },
      },
      {
        text: ", Nærmiljøsenteret og trosopplæringen i Vinger menighet. På klubben kan du henge med venner og ganske sikkert få noen nye. Vi har slush, og du kan spille biljard eller bordtennis frem til kl. 19:00 - da er det samling, med 'ord for kvelden' og quiz. Til slutt spiser vi noe godt sammen.",
      },
    ],
    [
      {
        h2: "Nærmiljøsenteret (Frivilligsentralen Kongsvinger Nærmiljøsenter)",
      },
      { h3: "Tacofredag" },
      {
        text: "Fredagen før første søndag i måneden samles vi til tacofredag i regi av Nærmiljøsenteret. Kom og nyt taco, utlodning, quiz og godt fellesskap.",
      },
    ],
    [
      { h3: "Sangkafé" },
      {
        text: "Nærmiljøsenteret arrangerer sangkafé med artister fra fjern og nær en av de siste fredagene i måneden.",
      },
    ],
    [
      { h3: "Kaffekroken" },
      {
        text: "En fredag i måneden legger Nærmiljøsenteret til rette for et trivelig fellesskap rundt kaffekoppen, der du inviteres med i Kaffekroken.",
      },
    ],
  ],
  about: [
    [
      { h2: "Bedehuset" },
      {
        text: "Kongsvinger bedehus ligger midt i sentrum av Kongsvinger i Tommelstadsgate 1 vis-a-vis Kongssenteret. Bedehuset eies av Normisjon og ",
      },
      {
        link: {
          label: "stod ferdig bygget",
          href: "https://www.nb.no/items/9c0eda75c447b89d0984b20e7d1e2cba?page=191",
          external: true,
        },
      },
      { text: " til innvielse " },
      {
        link: {
          label: "søndag 18. november 1877",
          href: "https://www.nb.no/items/URN:NBN:no-nb_digibok_2011111808057?page=365",
          external: true,
        },
      },
      { text: "." },
      { h3: "Aktiviteter" },
      {
        text: "Hver uke arrangeres det flere forskjellige ",
      },
      {
        link: {
          label: "aktiviteter",
          href: "/aktiviteter",
          external: false,
        },
      },
      {
        text: " på Kongsvinger Bedehus, for både store og små. Tidspunktene for de forskjellige arrangementene finner du i",
      },
      {
        link: {
          label: "programoversikten",
          href: "/program",
          external: false,
        },
      },
      {
        text: ".",
      },
      { h3: "Givertjeneste" },
      {
        text: "Hvis du skulle ønske å ",
      },
      {
        link: {
          label: "gi en gave",
          href: "/givertjeneste",
          external: false,
        },
      },
      {
        text: " til arbeidet tilknyttet Kongsvinger Bedehus, så er du hjertelig velkommen.",
      },
    ],
    [
      { h2: "Kongsvinger Misjonsforsamling" },
      {
        text: "Blant aktørene som arrangerer samlinger på Bedehuset finner vi Kongsvinger Misjonsforsamling (KMF) - et åpent, raust og forpliktende samarbeid mellom lokale misjonsforeninger tilknyttet de tre misjonsorganisasjonene ",
      },
      {
        link: {
          label: "Normisjon",
          href: "https://www.normisjon.no/vart-arbeid",
          external: true,
        },
      },
      { text: ", " },
      {
        link: {
          label: "Misjonsselskapet (NMS)",
          href: "https://nms.no/om-oss",
          external: true,
        },
      },
      { text: " og " },
      {
        link: {
          label: "Misjonssambandet (NLM)",
          href: "https://nlm.no/om-oss",
          external: true,
        },
      },
      {
        text: ". Misjonsforsamlingen er i sitt arbeid forpliktet på den Lutherske bekjennelse.",
      },
    ],
    [
      {
        quote:
          "Kongsvinger Misjonsforsamling vil arbeide for Guds rikes utbredelse lokalt og globalt. For å virkeliggjøre dette vil vi sette fokus på følgende: Et åpent og inkluderende fellesskap med omsorg for alle, forkynnelse av og undervisning i Guds Ord for å nære og styrke det åndelige liv i alle aldersgrupper (barn, ungdom og voksne), utadrettet virksomhet for å vinne mennesker for Jesus Kristus og inn i et kristent fellesskap, samt informasjon, inspirasjon og innsamling av midler til misjonsarbeidet hjemme og ute.",
      },
    ],
    [
      { h3: "Medlemskap" },
      {
        text: "Det er mulig å bli ",
      },
      {
        link: {
          label: "melde seg inn",
          href: "/medlemskap",
          external: false,
        },
      },
      {
        text: " i Kongsvinger Misjonsforsamling.",
      },
    ],
  ],
} as Location;
