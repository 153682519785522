import payload_plugin_KqLoiW8CK7im1b6TKzotqq5sBDb_JSBRdAdNf2YIg48 from "/home/bede/bede/node_modules/.pnpm/nuxt-vuefire@1.0.5_@firebase+app-types@0.9.3_firebase-admin@13.2.0_encoding@0.1.13__firebase-_jeghmmacdnd35mebxg7772niyy/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_HGGSv_1_4BdvWygMRQrQssmBBhB2b16jhmL5o423dLg from "/home/bede/bede/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.0_@types+node@22.13.10_db0@0.3.1_encoding@0.1.13_ioredis@5.6._ybh5rrivc6gdatjjfylwpamsnm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_IYrpj_aH5QFTivTEXKmhGW_P2mKW2gtngqnLWTYzwD4 from "/home/bede/bede/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.0_@types+node@22.13.10_db0@0.3.1_encoding@0.1.13_ioredis@5.6._ybh5rrivc6gdatjjfylwpamsnm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ZYhDgqDJ1ioQYWpERLFxZNTuU5P0pjnV3_Xq3hfnkUk from "/home/bede/bede/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.0_@types+node@22.13.10_db0@0.3.1_encoding@0.1.13_ioredis@5.6._ybh5rrivc6gdatjjfylwpamsnm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_5R4zFKrlzoL_2DCwyEZ2znbP8jl7Zk0kniKL5jNTr9o from "/home/bede/bede/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.0_@types+node@22.13.10_db0@0.3.1_encoding@0.1.13_ioredis@5.6._ybh5rrivc6gdatjjfylwpamsnm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_wkk1JW1Tv2I01Vy2QBk9HVGGfWvBXIhZcphV1ygxTY0 from "/home/bede/bede/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.0_@types+node@22.13.10_db0@0.3.1_encoding@0.1.13_ioredis@5.6._ybh5rrivc6gdatjjfylwpamsnm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_qTmw_RyYsjd_Q12GA3Ike_lJAaBn_hknuWQWnJScT7s from "/home/bede/bede/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.0_@types+node@22.13.10_db0@0.3.1_encoding@0.1.13_ioredis@5.6._ybh5rrivc6gdatjjfylwpamsnm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/home/bede/bede/.nuxt/components.plugin.mjs";
import prefetch_client_iBZOtlzAwKi1KHDK1i4k0o3gY3YPz98bT1mVXC7jekI from "/home/bede/bede/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.0_@types+node@22.13.10_db0@0.3.1_encoding@0.1.13_ioredis@5.6._ybh5rrivc6gdatjjfylwpamsnm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_bI_Ouk9qQ_MngIKog6lfj8b1FVDiQ51A_hQJjkBX8r8 from "/home/bede/bede/node_modules/.pnpm/nuxt-vuefire@1.0.5_@firebase+app-types@0.9.3_firebase-admin@13.2.0_encoding@0.1.13__firebase-_jeghmmacdnd35mebxg7772niyy/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_auth_plugin_client_5mchQbrFji2Q4C_SfSquLdjPW_bEWAXa5RNJAOVNHR0 from "/home/bede/bede/.nuxt/vuefire-auth-plugin.client.mjs";
import vuefire_plugin_3yC4ojquQ_IfYhLPId43C0hGmDvAqA3w4G_VdIrFV5M from "/home/bede/bede/.nuxt/vuefire-plugin.mjs";
import plugin_client_3me0fudiaMyQGYEJgDRLG4XFo4nQGQ2ZrSezp5hx_s4 from "/home/bede/bede/node_modules/.pnpm/nuxt-vuefire@1.0.5_@firebase+app-types@0.9.3_firebase-admin@13.2.0_encoding@0.1.13__firebase-_jeghmmacdnd35mebxg7772niyy/node_modules/nuxt-vuefire/dist/runtime/app-check/plugin.client.js";
import analytics_client_D__0bhX73uC0vhcsTxv5CvOp30_GkDRY3Sfq7hDlz_U from "/home/bede/bede/plugins/analytics.client.ts";
import vFocus_d0PxnC_PJyYuhUPiopHDW3FHcgys3il_UUhMqr65HtE from "/home/bede/bede/plugins/vFocus.ts";
export default [
  payload_plugin_KqLoiW8CK7im1b6TKzotqq5sBDb_JSBRdAdNf2YIg48,
  revive_payload_client_HGGSv_1_4BdvWygMRQrQssmBBhB2b16jhmL5o423dLg,
  unhead_IYrpj_aH5QFTivTEXKmhGW_P2mKW2gtngqnLWTYzwD4,
  router_ZYhDgqDJ1ioQYWpERLFxZNTuU5P0pjnV3_Xq3hfnkUk,
  navigation_repaint_client_5R4zFKrlzoL_2DCwyEZ2znbP8jl7Zk0kniKL5jNTr9o,
  check_outdated_build_client_wkk1JW1Tv2I01Vy2QBk9HVGGfWvBXIhZcphV1ygxTY0,
  chunk_reload_client_qTmw_RyYsjd_Q12GA3Ike_lJAaBn_hknuWQWnJScT7s,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_iBZOtlzAwKi1KHDK1i4k0o3gY3YPz98bT1mVXC7jekI,
  plugin_client_bI_Ouk9qQ_MngIKog6lfj8b1FVDiQ51A_hQJjkBX8r8,
  vuefire_auth_plugin_client_5mchQbrFji2Q4C_SfSquLdjPW_bEWAXa5RNJAOVNHR0,
  vuefire_plugin_3yC4ojquQ_IfYhLPId43C0hGmDvAqA3w4G_VdIrFV5M,
  plugin_client_3me0fudiaMyQGYEJgDRLG4XFo4nQGQ2ZrSezp5hx_s4,
  analytics_client_D__0bhX73uC0vhcsTxv5CvOp30_GkDRY3Sfq7hDlz_U,
  vFocus_d0PxnC_PJyYuhUPiopHDW3FHcgys3il_UUhMqr65HtE
]